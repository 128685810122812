import React from 'react';
import { navigate } from 'gatsby';

const Page = ({ location }) => {
  React.useEffect(() => {
    navigate(`/result/scanPromptpay/${location.search}`, { replace: true });
  }, [location.search]);
  return false;
};

export default Page;
